<template>
  <div class="main">
    <Header />
    <div id="box">
      <div id="sideBar" v-if="$route.path !== '/box/personCenter' && $route.path != '/box/companyCenter' && $route.path != '/box/resetPsd'">
        <SideBar />
      </div>
      <div
        id="content"
        :class="{ 'scrollbar-class scrollbar-class2': $route.path == '/box/search' || $route.path == '/box/companyDetails' || $route.path == '/box/dataView'
                || $route.path == '/box/compare' || $route.path == '/box/mlist' || $route.path == '/box/findCompetitive'}"
      >
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header/header";
import SideBar from "@/components/sideBar/sideBar";
export default {
  components: {
    Header,
    SideBar,
  },
  data() {
    return {
    };
  },
  beforeCreate() {
    if((typeof(this.$store.state.userInfo.vip_type) === 'undefined' || typeof(this.$store.state.userInfo.account) === 'undefined') && localStorage.getItem('ajt_token')) {
      this.$C.getUserInfo().then((data) => {
        this.$store.state.userInfo = data
      })
    }
  },
};
</script>
<style scoped lang="scss" src="@/views/box/box.scss"></style>
<style lang="scss" src="@/assets/css/table.scss"></style>

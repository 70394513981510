<template>
  <div class="sidebar_nav">
    <el-menu
            router
            :default-active="active"
            active-text-color="#1f81f8"
            class="el-menu-vertical">
      <el-menu-item index="/box/dataView">
        <img class="default_img" src="@/assets/img/sideBar/icon_home.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_home_a.png" alt="">
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="/box/search/findAdvertising" :class="$route.matched[1].path == '/box/search'?'is-active':''">
        <img class="default_img" src="@/assets/img/sideBar/icon_ad.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_ad_a.png" alt="">
        <span>查广告</span>
      </el-menu-item>
      <el-menu-item index="/box/findCompetitive">
        <img class="default_img" src="@/assets/img/sideBar/icon_ss.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_ss_a.png" alt="">
        <span>找竞品</span>
      </el-menu-item>
      <el-menu-item index="/box/compare">
        <img class="default_img" src="@/assets/img/sideBar/icon_db.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_db_a.png" alt="">
        <span>竞品对比</span>
<!--        <img v-if="$store.state.userInfo.vip_type == 5" style="margin-left: 8px; width: 17px" src="@/assets/img/sideBar/lock.png" alt="">-->
      </el-menu-item>
      <el-submenu index="">
        <template #title>
          <img class="default_img" src="@/assets/img/sideBar/icon_jc.png" alt="">
          <img class="active_img" src="@/assets/img/sideBar/icon_jc_a.png" alt="">
          <span>推广监测</span>
        </template>
        <el-menu-item index="/box/dynamic">监测动态</el-menu-item>
        <el-menu-item index="/box/mlist">监测管理</el-menu-item>
      </el-submenu>
      <el-menu-item index="/box/collection">
        <img class="default_img" src="@/assets/img/sideBar/icon_sc.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_sc_a.png" alt="">
        <span>我的收藏</span>
      </el-menu-item>
      <el-menu-item index="/box/export">
        <img class="default_img" src="@/assets/img/sideBar/icon_dc.png" alt="">
        <img class="active_img" src="@/assets/img/sideBar/icon_dc_a.png" alt="">
        <span>我的导出</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
  export default {
    name: "sideBar",
    data() {
      return {
        activeIndex: null
      }
    },
    computed : {
      active() {
        return this.$route.path
      }
    },
    mounted() {

    }
  }
</script>

<style scoped lang="scss" src="./sideBar.scss"></style>
<style>
  /*清除样式*/
  .sidebar_nav .el-submenu__title {
    padding: 0 !important;
  }
  .sidebar_nav  li {
    padding: 0 !important;
  }
  /*二级导航添加样式*/
  .sidebar_nav .el-menu--inline li {
    padding-left: 55px !important;
  }
  .sidebar_nav .el-menu-vertical .is-active {
    background-color: #e8f2fe;
  }
  .sidebar_nav .el-menu {
    border: none;
  }
  .sidebar_nav .el-menu-vertical .is-active .default_img {
    display: none;
  }
  .sidebar_nav .el-menu-vertical .is-active .active_img {
    display: inline-block;
  }
  .sidebar_nav .is-opened .el-submenu__title {
      background-color: #ffffff;
  }
  .sidebar_nav .is-opened .el-submenu__title .active_img{
      display: none;
  }
  .sidebar_nav .is-opened .el-submenu__title .default_img{
      display: inline-block;
  }
</style>

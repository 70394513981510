<template>
  <div id="header">
    <div class="header_main">
        <div class="left">
            <router-link to="/box/dataView" id="logo">
                <img src="@/assets/img/header/logo.png" alt="" />
            </router-link>
            <div class="search_box" v-if="$route.name != 'dataView' && $route.matched[1].path != '/box/search'">
                <div class="search_type">
                    <div class="type_item" :class="headSearchType == 'ad'?'active_item':''" @click="changeHeadSearchType('ad')">查广告创意</div>
                    <div class="type_item" :class="headSearchType == 'wd'?'active_item':''" @click="changeHeadSearchType('wd')">查关键词</div>
                    <div class="type_item" :class="headSearchType == 'cp'?'active_item':''" @click="changeHeadSearchType('cp')">查推广公司</div>
                </div>
                <div class="input">
                    <input @input="getSuggestion" @blur="hiddenHistory" type="text" v-model="searchKey" @keypress.enter="handleHeadSearch" :placeholder="headSearchType=='ad'?'请输入推广关键词':headSearchType=='wd'?'请输入关键词':'请输入网站名称/网址、公司名、行业名、推广关键词等'">
                    <div class="search_btn" @click="handleHeadSearch">查一查</div>
                    <div class="empty_input" v-if="showEmptyInput  && searchSuggestList.length">
                        <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
                        <span>你还未输入关键词</span>
                    </div>
                </div>
                <div class="search_history" v-show="showInputSuggest">
                    <div class="search_item" v-for="(item,i) in searchSuggestList" :key="i" @click="selectHistory(item.company_name)">{{item.company_name}}</div>
                </div>
            </div>
        </div>
      <div class="right">
        <div class="notice" v-if="false">
          <img src="@/assets/img/header/notice.png" alt="" />
          <span>消息中心<font>99</font></span>
        </div>
        <div class="user">
            <img v-if="userInfo.vip_type == 5" class="head" src="@/assets/img/header/user_base.png" alt="" />
            <img v-if="userInfo.vip_type == 10" class="head" src="@/assets/img/header/user_common.png" alt="" />
            <img v-if="userInfo.vip_type == 20" class="head" src="@/assets/img/header/user_vip.png" alt="" />
            <img v-if="userInfo.vip_type == 30" class="head" src="@/assets/img/header/user_svip.png" alt="" />
          <span>{{ userInfo.user_name }}</span>
          <img
            class="icon bottom"
            src="@/assets/img/header/bottom.png"
            alt=""
          />
          <img class="icon top" src="@/assets/img/header/top.png" alt="" />
          <div class="user-box">
            <div class="ub-info">
              <img v-if="userInfo.vip_type == 5" src="@/assets/img/header/user_base.png" alt="" />
              <img v-if="userInfo.vip_type == 10" src="@/assets/img/header/user_common.png" alt="" />
              <img v-if="userInfo.vip_type == 20" src="@/assets/img/header/user_vip.png" alt="" />
              <img v-if="userInfo.vip_type == 30" src="@/assets/img/header/user_svip.png" alt="" />
              <div class="ub-info-box">
                <h3>{{ userInfo.user_name }}</h3>
                <p>{{ userInfo.account }}</p>
              </div>
            </div>
            <div v-if="parseInt(userInfo.account_cnt) > 1" class="change" @click="$router.push('/changeSubject')">切换</div>
            <a
              href="javascript:;"
              class="ub-list"
              :class="{ 'ub-list-company': index == 0 }"
              v-for="(el, index) in tabs"
              :key="index"
              @click="routerFn(el.router)"
            >
              <img class="ub-list-icon" :src="el.icon" alt="" />
              <img class="ub-list-icon-hover" :src="el.icon_hover" alt="" />
              <span v-if="index == 0" :title="el.name">{{
                el.name && el.name.length >= 12 ? el.name.substring(0, 12) + "..." : el.name
              }}</span>
              <span v-else>{{ el.name }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

      <el-dialog
          title="提示"
          :before-close="handleClose"
          custom-class="head_jurisdiction"
          :visible.sync="dialogVisible">
          <div class="container">
              <img src="@/assets/img/header/warning_icon.png"/>
              <span>权限已过期，当前权限等级为基础版权限。</span>
          </div>
          <div class="footer_btn">
              <myButton type="primary" width="80" height="33" class="button" @click="handleClose">
                  <span slot="text">确定</span>
              </myButton>
          </div>
      </el-dialog>

      <el-dialog
          :before-close="handleMonitorClose"
          custom-class="head_monitor"
          :visible.sync="dialogMonitor">
          <template #title>
              <div class="title">选择你关注的的竞品公司</div>
              <div class="sub">
                  <span>根据选择的竞品，我们将监测它的营销动态并推送给你</span>
                  <el-tooltip placement="top" effect="light">
                      <div slot="content">
                          推送方式包括短信、微信公众号，<br>默认开启短信推送可自定义关闭。
                      </div>
                      <img src="../../assets/img/search/icon_more.png" height="14" width="14"/>
                  </el-tooltip>
              </div>
          </template>
          <div class="container scrollbar-class">
              <span class="list_title">相关竞品公司推荐</span>
              <div class="monitor_list">
                <div class="monitor_item" :class="item.select?'selected':''" v-for="(item, i) in monitor_list" :key="i" @click="changeStatus(item)">
                    <img v-if="!item.select" src="../../assets/img/header/select_icon.png"/>
                    <img v-else src="../../assets/img/header/selected_icon.png"/>
                    <span class="company_name">{{item.company_name}}</span>
                </div>
              </div>
          </div>
          <template slot="footer">
              <span>请选择3家以上竞品公司</span>
              <myButton :disable="select_cnt < 3" type="primary" width="80" height="33" class="button" @click="handleSubmit">
                  <span slot="text">保存</span>
              </myButton>
          </template>
      </el-dialog>
  </div>
</template>
<script>
import myButton from ".././myButton/myButton";
import * as api from "@/api/common";
import * as search from "@/api/search"

export default {
  components: {
    myButton,
  },
  data() {
    return {
      tabs: [
        {
          icon: require("@/assets/img/header/company_icon2.png"),
          icon_hover: require("@/assets/img/header/company_hover_icon.png"),
          name: "",
          router: "company",
        },
        {
          icon: require("@/assets/img/header/personal_icon.png"),
          icon_hover: require("@/assets/img/header/personal_hover_icon.png"),
          name: "个人中心",
          router: "/box/personCenter",
        },
        {
          icon: require("@/assets/img/header/exit_icon.png"),
          icon_hover: require("@/assets/img/header/exit_hover_icon.png"),
          name: "退出账户",
          router: "exit",
        },
      ],
      userInfo: {},
      dialogVisible: false,
      dialogMonitor: false ,
      monitor_list: [],
      max_select: null,
      headSearchType: 'ad',
      searchKey: '',
      showEmptyInput: false,
      showInputSuggest: false,
      searchSuggestList: [],
    };
  },
  created() {
    this.$C.getUserInfo().then((data) => {
      this.$store.state.userInfo = data
      this.userInfo = this.$store.state.userInfo
      this.tabs[0].name = this.$store.state.userInfo.holder_name;
      if(!data.holder_auth_name) {
        this.$router.push('/completeInformation')
      }
      api.get_user_todo_config().then(res => {
        if(res.data.result_code == 0 && res.data.data.setting_monitor_company) {
          api.get_user_setting_monitor_company().then(res => {
            if(res.data.result_code == 0) {
              this.monitor_list = res.data.data.se_rows
              this.max_select = res.data.data.max_select
              this.dialogMonitor = true
            }
          })
        }
      })
    })
    if(this.userInfo.vip_type == 5 && !localStorage.getItem('overData_tip_show')) {
      this.dialogVisible = true;
      localStorage.setItem('overData_tip_show', 'true')
    }
  },
  computed: {
    select_cnt() {
      let cnt = 0;
      for (var i = 0; i < this.monitor_list.length; i++) {
        this.monitor_list[i].select && cnt ++
      }
      return cnt
    }
  },
  methods: {
    hiddenHistory() {
      setTimeout(() => {
        this.showInputSuggest = false
      },500)
    },
    selectHistory(word) {
      this.searchKey = word;
      this.showInputSuggest = false
      this.handleHeadSearch();
    },
    getSuggestion() {
      if(this.searchKey && this.headSearchType == 'cp') {
        this.showEmptyInput = false;
        this.showInputSuggest = true ;
        let params = {
          keyword: this.searchKey,
          type: 1
        };
        search.get_ad_compete_list_search(params).then(res => {
          if(res.data.result_code == 0) {
            this.searchSuggestList = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
    },
    changeStatus(item) {
      if(!item.select) {
        if (this.select_cnt <= this.max_select - 1) {
          item.select = !item.select
        } else {
          this.$message({
            type: 'error',
            message: '你当前最多可添加'+this.max_select+'家公司监测'
          })
        }
      } else {
        item.select = !item.select
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleMonitorClose() {
      this.dialogMonitor = false;
    },
    changeHeadSearchType(type) {
      if(this.headSearchType != type) {
        this.headSearchType = type
        this.showInputSuggest = false
      }
    },
    handleSubmit() {
      let digests = []
      for (var i = 0; i < this.monitor_list.length; i++) {
       if (this.monitor_list[i].select) {
         digests.push(this.monitor_list[i].digest)
       }
      }

      let p = new Promise((resolve, reject) => {
        api.do_batch_monitor({digests: digests.join('_')}).then(res => {
          if(res.data.result_code == 0) {
            resolve(res.data.data);
            this.$message({
              type: 'success',
              message: '监测成功！'
            })
            this.dialogMonitor = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      })
      return p;
    },
    handleHeadSearch() {
      if(!this.searchKey) {
        this.showEmptyInput = true;
        setTimeout(() => {
          this.showEmptyInput = false;
        }, 3000)
        return
      }
      let path = '/box/search'
      let query = { word: this.searchKey}
      switch (this.headSearchType) {
        case "ad":
          path += '/findAdvertising'
          break;
        case "wd":
          path += '/findWord'
          break;
        case "cp":
          path += '/findCompany'
          break
      }
      this.$router.push({
        path,
        query,
      })
      this.searchKey = ''
    },
    routerFn(router) {
      if (router == "/box/personCenter") {
        this.$router.push({
          path: router,
        });
      } else if (router == "exit") {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if(flag) {
          this.$router.push({
            path: "/login_h5",
          });
        } else {
          this.$router.push({
            path: "/login",
          });
        }
        localStorage.removeItem("ajt_token");
      }
    },
    changeSearchType(type) {
      this.headSearchType = type
    },
  },
};
</script>
<style scoped lang="scss" src="./header.scss"></style>

<style lang="scss">
.head_jurisdiction {
    width: 435px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        font-size: 16px;
        color: #333333;
    }
    .el-dialog__body {
        .container {
            display: flex;
            align-items: center;
            img {
                width: 16px;
                height: 16px;
                margin: 0 9px;
            }
        }
        .footer_btn {
            margin-top: 29px;
            text-align: right;
        }
    }
}

.head_monitor {
    width: 595px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .el-dialog__header {
        padding: 20px;
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid #ebeff2;
        .title {
            margin-bottom: 10px;
        }
        .sub {
            font-size: 14px;
            color: #999999;
            display: flex;
            align-items: center;
            img {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
    .el-dialog__body {
        padding: 20px 18px;
        font-size: 14px;
        .container {
            max-height: 340px;
            overflow-y: scroll;
            .list_title {
                color: #999;
            }
            .monitor_list {
                margin-top: 17px;
                .monitor_item {
                    width: 236px;
                    padding: 16px;
                    display: inline-flex;
                    align-items: center;
                    border-radius: 5px;
                    border: solid 1px #ebeff2;
                    cursor: pointer;
                    color: #333333;
                    font-size: 14px;
                    margin-bottom: 15px;
                    &:nth-child(2n+1) {
                        margin-right: 13px;
                    }
                    img {
                        width: 14px;
                        height: 14px;
                        margin-right: 11px;
                    }
                    .company_name {
                        white-space: nowrap;
                        max-width: 200px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .selected {
                    border: solid 1px #1f81f8;
                    color: #1f81f8;
                }
            }
        }
    }
    .el-dialog__footer {
        padding: 12px 22px;
        border-top: 1px solid #ebeff2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
    }
}
</style>
